footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 5rem 0;
}

footer a {
  font-weight: 600;
  text-decoration: none;
  transition: 0.5s ease-in-out;
  background-color: #08aeea;
  background-image: linear-gradient(45deg, #313638 0%, #313638 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

footer a:hover {
  background-color: #21d4fd;
  background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

footer a:first-of-type:hover {
  background-color: #a9c9ff;
  background-image: linear-gradient(45deg, #08aeea 0%, #2af598 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
