.about-wrapper {
  background-color: #fff;
  background-image: linear-gradient(45deg, #fbda61 0%, #ff5acd 100%);
  max-width: 60vw;
  margin: auto;
  border-radius: 3rem;
  padding: 1rem;
}

#about {
  scroll-margin-top: 100px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 3rem;
  border-radius: 2.3rem;
}

#about h3 {
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.reverse-icon {
  transform: scaleX(-1);
  display: inline-block;
}

.backend-content {
  margin: 3rem 0;
}

.icons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-text {
  font-weight: 500;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.icon {
  width: 50px;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2));
}

.icons-table {
  width: 70%;
  margin: auto;
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.about-text {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 2px;
  text-align: justify;
}

.about-text p:not(:last-of-type) {
  margin-bottom: 2rem;
}

.stack {
  max-width: 60vw;
  margin: auto;
}

.banner {
  max-width: 90%;
  margin: 0 auto 10rem auto;
  position: relative;
  padding-block: 8px;
  overflow: hidden;
  user-select: none;
  display: flex;
  --gap: 3rem;
  gap: var(--gap);
}

.banner::before,
.banner::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20%;
  pointer-events: none;
  z-index: 100;
}

.banner::before {
  left: 0;
  background: linear-gradient(to right, #ffffff, transparent);
}

.banner::after {
  right: 0;
  background: linear-gradient(to left, #ffffff, transparent);
}

.banner:hover ul {
  animation-play-state: paused;
}

.banner ul {
  display: flex;
  align-items: center;
  gap: var(--gap);
  white-space: nowrap;
  animation: scroll 40s linear infinite;
}

.banner li {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@media only screen and (max-width: 768px) {
  .stack,
  .banner {
    max-width: 100%;
  }

  .banner {
    margin-bottom: 5rem;
  }

  .about-wrapper {
    max-width: 100%;
    margin: 0 1rem;
    padding: 1rem;
    border-radius: 1rem;
  }

  #about {
    max-width: 100%;
    padding: 2rem;
    border-radius: 1rem;
  }
}
