#nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  padding: 2rem 5rem;
  background-color: transparent;
  transition: all 0.5s ease;
  font-weight: 600;
  z-index: 1000;
}

#nav.scrolled {
  padding: 1rem;
}

#nav img {
  height: 30px;
  filter: invert(1);
}

#nav ul {
  display: flex;
  list-style-type: none;
  gap: 2rem;
}

#nav ul li a {
  text-decoration: none;
  transition: all 0.5s ease;

  background-color: #08aeea;
  background-image: linear-gradient(45deg, #313638 0%, #313638 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#nav ul li:first-of-type:hover a:hover {
  background-color: #a9c9ff;
  background-image: linear-gradient(45deg, #08aeea 0%, #2af598 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#nav ul li a:hover {
  background-color: #21d4fd;
  background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#nav ul li:last-of-type a:hover {
  background-color: #ff9a8b;
  background-image: linear-gradient(
    90deg,
    #ff9a8b 0%,
    #ff6a88 55%,
    #ff99ac 100%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 768px) {
  #nav {
    backdrop-filter: blur(10px);
  }
}
