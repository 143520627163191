.container {
  max-width: 1200px;
  margin: 0 auto;
}

@keyframes dot-blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
}
