#hero {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(0deg, #fff 0%, #f5f5f5 100%);
}

.hero-header {
  display: block;
}

.hero-content {
  width: 60vw;
  margin: auto;
  font-size: 2.5rem;
}

#hero .highlight {
  background-color: #21d4fd;
  background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.developer {
  background-color: #08aeea;
  background-image: linear-gradient(45deg, #08aeea 0%, #2af598 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.golfer {
  background-color: #f4d03f;
  background-image: linear-gradient(132deg, #f4d03f 0%, #16a085 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.runner {
  background-color: #a9c9ff;
  background-image: linear-gradient(180deg, #a9c9ff 0%, #ffbbec 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lover {
  background-color: #ff9a8b;
  background-image: linear-gradient(
    90deg,
    #ff9a8b 0%,
    #ff6a88 55%,
    #ff99ac 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 768px) {
  #hero {
    height: 100vh;
  }

  .hero-paragraph {
    display: none;
  }

  .hero-content {
    width: 80vw;
    font-size: 2rem;
  }
}
