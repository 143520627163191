#projects {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0 10rem 0;
  gap: 10rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 70%;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 1rem;
  box-shadow: 0px 12px 29px -11px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
  background-color: white;
}

.card img {
  border-radius: 0.2rem;
}

.card:hover .dot {
  opacity: 1;
  animation: blurAnimation 5s infinite;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-container {
  display: flex;
  gap: 1rem;
}

.icon-container img {
  width: 1.5rem;
}

.card h2 {
  margin: 2rem 0;
}

.card p {
  text-align: justify;
  margin-bottom: 2rem;
  line-height: 150%;
}

.card a {
  text-decoration: none;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

.card a:hover {
  opacity: 0.5;
}

.card a:not(:last-of-type) {
  margin-right: 2rem;
}

.left-push {
  margin-left: 30%;
}

.right-push {
  margin-right: 30%;
}

.dot {
  position: absolute;
  border-radius: 50%;
  z-index: -1000;
  width: 20rem;
  height: 20rem;
  filter: blur(50px);
  opacity: 0.6;
  transition: all 0.5s ease-in-out;
}

.dot-blue {
  background-color: #08aeea;
  top: 3rem;
  right: 50%;
  transform: translate(50%);
}

.dot-mid {
  background-color: #19d2c0;
  bottom: 3rem;
  right: 3rem;
}

.dot-green {
  background-color: #2af598;
  bottom: 3rem;
  left: 3rem;
}

@keyframes blurAnimation {
  0% {
    filter: blur(50px);
  }
  50% {
    filter: blur(100px);
  }
  100% {
    filter: blur(50px);
  }
}

@media only screen and (max-width: 768px) {
  #projects {
    width: 100%;
    padding: 5rem 1rem;
    gap: 5rem;
  }

  .card {
    width: 100%;
  }

  .card-header {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .card h2 {
    font-size: 1.3rem;
    margin: 1rem 0;
  }

  .icon-container {
    font-size: 1rem;

    svg {
      fill: var(--muted);
    }
  }
  .card p {
    color: var(--muted);
  }
  .left-push {
    margin-left: 0%;
  }

  .right-push {
    margin-right: 0%;
  }
}
